import { ethers } from 'ethers';
import abi from './abi/taiko/mint.json';
import galxeLogoUrl from '../assets/entrypasses/Galxe.png';
import taikoLogoUrl from '../assets/entrypasses/Taiko.png';
import lineaLogoUrl from '../assets/entrypasses/Linea.png';
import microLogoUrl from '../assets/entrypasses/Micro.png';
import nftfeedLogoUrl from '../assets/entrypasses/Nftfeed.png';
import monadLogoUrl from '../assets/entrypasses/Monad.png';
import mintLogoUrl from '../assets/entrypasses/Mint.png';
import mantaLogoUrl from '../assets/entrypasses/Manta.png';
import morphLogoUrl from '../assets/entrypasses/Morph.png';
import bitlayerLogoUrl from '../assets/entrypasses/Bitlayer.png';
export const chains = [
  {
    id: '42766',
    name: 'ZKFair Mainnet',
    nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
    rpcUrls: ['https://rpc.zkfair.io'],
    contracts: {
      // Entrypass: new ethers.Contract(''),
      NFT: new ethers.Contract(
        '0x5aB420bd468BcdCa2660469cfd2AA684B6f9a0dc',
        abi
      ),
      Stake: new ethers.Contract(
        '0x1670080000000000000000000000000000000003',
        abi
      ),
      Token: new ethers.Contract(
        '0x14027051bB9fEDbb8D8559416b056C2CBf3aA238',
        abi
      ),
      //Store: new ethers.Contract(''),
    },
    features: [],
  },
];

export const entryPasses = [
  {
    name: 'taiko',
    src: taikoLogoUrl,
    title: 'Taiko Entry Pass',
    path: `taiko`,
    isTestnet: false,
    chainIds: [167000],
    contract: 'NFT',
  },
  {
    name: 'galxe',
    src: galxeLogoUrl,
    title: 'Galxe Entry Pass',
    path: `galxe`,
    isTestnet: false,
    chainIds: [56],
    contract: 'NFT',
  },
  {
    name: 'Monad',
    src: monadLogoUrl,
    title: 'Monad Entry Pass',
    path: `monad`,
    isTestnet: true,
    chainIds: [10143],
    contract: 'NFT',
  },
  {
    name: 'micro',
    src: microLogoUrl,
    title: 'Micro3 Entry Pass',
    path: `micro3`,
    isTestnet: false,
    chainIds: [56, 137],
    contract: 'MicMint',
  },

  {
    name: 'nftfeed',
    src: nftfeedLogoUrl,
    title: 'NFTFeed Entry Pass',
    path: `nftfeed`,
    isTestnet: false,
    chainIds: [56, 137],
    contract: 'NfeedMint',
  },
  {
    name: 'Mint',
    src: mintLogoUrl,
    title: 'Mint Entry Pass',
    path: `mint`,
    isTestnet: false,
    chainIds: [185],
    contract: 'NFT',
  },
  {
    name: 'Manta',
    src: mantaLogoUrl,
    title: 'Manta Entry Pass',
    path: `manta`,
    isTestnet: false,
    chainIds: [169],
    contract: 'NFT',
  },
  {
    name: 'Linea',
    src: lineaLogoUrl,
    title: 'Linea Entry Pass',
    path: `linea`,
    isTestnet: false,
    chainIds: [59144],
    contract: 'NFT',
  },
  {
    name: 'Morph',
    src: morphLogoUrl,
    title: 'Morph Entry Pass',
    path: `morph`,
    isTestnet: false,
    chainIds: [2818],
    contract: 'NFT',
  },
  {
    name: 'Bitlayer',
    src: bitlayerLogoUrl,
    title: 'Bitlayer Entry Pass',
    path: `bitlayer`,
    isTestnet: false,
    chainIds: [200901],
    contract: 'NFT',
  },
];
